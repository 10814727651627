<template>
  <div>
    <div class="step-three-whole-wrapper">
      <div class="step-three-wrapper">
        <div class="left">
          <div>
            <button @click="gotoPage('google_wizard')"
                    :disabled="currentPlan && currentPlan.is_influencer">
              <span> Create a Google campaign</span>
              <div class="img-wrapper">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/300/300221.png"
                  alt=""
                />
              </div>
            </button>
          </div>
          <div>
            <button @click="gotoPage('create_campaign_facebook')"
                    :disabled="currentPlan && currentPlan.is_influencer">
              <span> Create a Facebook campaign</span>

              <div class="img-wrapper">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/733/733547.png"
                  alt=""
                />
              </div>
            </button>
          </div>
          <div>
            <button @click="gotoPage('create_influencer')">
              <span> Create a Influencer campaign</span>

              <div class="img-wrapper">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/4515/4515737.png"
                  alt=""
                />
              </div>
            </button>
          </div>
        </div>
        <div class="right">
          <div>
            <p class="text">
              If you already have existing Amazon Attribution campaigns that you
              would like to see on the dashboard, go to Link Existing Campaigns
            </p>
          </div>
          <div>
            <div>
              <button @click="gotoPage('mapping')">
                Link Existing Campaigns
                <div class="img-wrapper">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/282/282100.png"
                    alt=""
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      currentPlan: 'plan/getCurrentPlan'
    })
  },
  methods: {
    gotoPage(route) {
      this.$router.push({name: route});
      this.$emit('hide');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/style.scss";
@import "./style.scss";
</style>
