<template>
  <p class="text">
    Connect your Google/Facebook account. This will allow us access to your
    ads data allow you to create campaigns with attribution links through our
    system
  </p>

  <div class="row mt-5">
    <div class="col-6">
      <a class="google-btn"
         type="button"
         @click.prevent="$refs.googlePopup.show()"
         v-if="settings && !settings.google_user_id">
        <div class="google-icon-wrapper">
          <img
            class="google-icon"
            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
          />
        </div>
        <p class="btn-text">Sign in with Google</p>
      </a>

      <a class="google-btn"
         type="button"
         v-if="settings && settings.google_user_id">
        <div class="google-icon-wrapper">
          <img
            class="google-icon"
            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
          />
        </div>
        <p class="btn-text">{{ settings.google_name }}</p>
      </a>

      <div class="alert alert-danger mt-2"
           v-if="settings && settings.google_user_id && !settings.google_ads_scope"
           role="alert">
        <strong><i class="fas fa-exclamation-triangle"></i> Google Ads Permission Missing</strong>

        <button class="btn btn-sm btn-danger ml-2"
                @click.prevent="socialLogout('google')">Disconnect</button>
      </div>
    </div>
    <div class="col-6">
      <button type="button"
              v-if="settings && settings.fb_user_id"
              class="social-btn facebook-btn">
        <div class="social-btn-icon">
          <i class="fab fa-facebook-f me-2"></i>
        </div>
        {{ settings.fb_name }}
      </button>

      <button type="button"
              v-else
              @click.prevent="fbLogin"
              class="social-btn facebook-btn">
        <div class="social-btn-icon">
          <i class="fab fa-facebook-f me-2"></i>
        </div>
        Connect To Facebook
      </button>
    </div>
  </div>

  <div class="free-trail-button-wrapper mt-3">
    <button @click="finish"
            class="free-trail-button step-one-btn-width relative">
      <span v-if="settings && (settings.google_user_id || settings.fb_user_id)">Finish</span>
      <span v-else>Skip</span>
      <span class="free-trail-right-arrow absolute">
          <i class="fa-solid fa-arrow-right"></i>
        </span>
    </button>
  </div>

  <teleport to="body">
    <google-popup ref="googlePopup" />
  </teleport>
</template>

<script>
import settingsMixins from "@/mixins/settingsMixins";
import GooglePopup from "./GooglePopup.vue";
import {mapGetters} from "vuex";

export default {
  components: {GooglePopup},
  mixins: [settingsMixins],
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
    }),
  },
  methods: {
    finish() {
      this.$emit('goNext');
      this.axios.post('/settings', {
        is_setup_done: 1
      })
    },
    socialLogout(service) {
      this.axios.post('/settings/logout/'+service)
        .then(response => {
          this.$store.commit('settings/setSettings', response.data.data);
        });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/style.scss";
</style>
