<template>
  <div class="modal-header border-0">
    <div class="row" style="width: 100% !important;">
      <div class="col-md-11">
        <h1 :class="['modal-title heading', {'revoked': revoked}]">{{ title }}</h1>
      </div>
      <div class="col-md-1 text-right">
        <a type="button"
           @click="hide"
           class="btn-close">X</a>
      </div>
    </div>
  </div><!--end modal-header-->
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <Transition name="slide-fade">
          <StepOne v-if="displayStep === 1"
                   @goNext="displayStep = 2" />
        </Transition>
        <Transition name="slide-fade">
          <license v-if="displayStep === 2"
                   @licenseApplied="licenseApplied"
                   @goNext="displayStep = 3"/>
        </Transition>
        <Transition name="slide-fade">
          <StepTwo v-if="displayStep === 3"
                   @goNext="displayStep = 4"/>
        </Transition>
        <Transition name="slide-fade">
          <StepThree v-if="displayStep === 4"
                     @goNext="displayStep = 5" />
        </Transition>
        <Transition name="slide-fade">
          <step-four v-if="displayStep === 5" @hide="hide" />
        </Transition>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <Stepper :dispayBtnNo="displayStep" />
  </div>
</template>

<script>
import StepOne from "./components/step-one/StepOne.vue";
import StepTwo from "./components/step-two/StepTwo.vue";
import StepThree from "./components/step-three/StepThree.vue";
import StepFour from "./components/step-four/StepFour.vue";
import License from "./components/step-license/License.vue";
import Stepper from "./components/Stepper.vue";
import {mapGetters} from "vuex";
export default {
  components: { StepOne, StepTwo, StepThree, StepFour, Stepper, License },
  data() {
    return {
      displayStep: 1,
      account_id: this.account ? this.account.id : '',
    }
  },
  computed: {
    title() {
      if (this.displayStep === 1 && this.revoked)
        return "Your license has been revoked!";
      else if (this.displayStep === 1)
        return "Welcome to Attribution Insights!";
      else if (this.displayStep === 2)
        return "License";
      else if (this.displayStep === 3)
        return "Billing Information";
      else if (this.displayStep === 4)
        return "Connect Ad Platforms";
      else if (this.displayStep === 5)
        return "Where do I go from here?";
      else
        return "";
    },
    ...mapGetters({
      user: 'auth/getUser',
      revoked: 'plan/getRevoked',
      account: 'auth/getAccount',
    })
  },
  mounted() {
    if (this.account)
      this.account_id = this.account.id;
  },
  methods: {
    licenseApplied(data) {
      if (data.type === 'influencer')
        this.displayStep = 5;
      else
        this.displayStep = 4
    },
    changeStep(step) {
      this.displayStep = step
    },
    hide() {
      this.$emit('hide');
    },
    changeAccount() {
      let account = this.user.accounts.find(a => a.id === this.account_id);

      this.$store.dispatch('auth/changeAccount', account)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./style/style.scss";

.revoked {
  background-color: red !important;
  background-image: none;
}
</style>
